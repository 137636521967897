<template>
  <div>
    <CRow>
      <CCol>
        <RequestForm/>
      </CCol>
    </CRow>

    <CRow>
      <CCol>

      </CCol>
    </CRow>

  </div>
</template>

<script>
import RequestForm from './components/RequestForm.vue'

export default {
  name: 'DriveRequest',
  components: {
    RequestForm
  },
  data () {
    return {

    }
  },
  methods: {
  }
}
</script>
